import * as basicLightbox from "basiclightbox";

class Quiz {
    constructor(selector) {
        this.selector = selector;

        this.startScreen = selector.querySelector('[data-element="start-screen"]');
        this.quizContent = selector.querySelector('[data-element="content"]');
        this.resultScreen = selector.querySelector('[data-element="result"]');

        this.startButton = this.startScreen.querySelector('[data-element="start"]');

        this.question = this.quizContent.querySelector('[data-element="question"]');
        this.answersContainer = this.quizContent.querySelector('[data-element="answers"]');
        this.nextButton = this.answersContainer.querySelectorAll('[data-element="answer"]');
        this.timerContainer = this.quizContent.querySelector('[data-element="timer"]');

        this.scoreContainer = this.resultScreen.querySelector('[data-element="score"]');
        this.resultTime = this.resultScreen.querySelector('[data-element="timer"]');

        this.questionCount = selector.getAttribute('data-count');

        this.questionList = selector.getAttribute('data-questions').split(',');

        this.currentQuestion = 0;
        this.score = 0;
        this.fullTime = 0;
        this.time = 0;
        this.timer = null;

        this._nextQuestion = this._nextQuestion.bind(this);
        this._startQuiz = this._startQuiz.bind(this);
        this._sendForm = this._sendForm.bind(this);

        this.nextButton.forEach(element => {
           element.addEventListener('click', this._nextQuestion);
        });
        this.startButton.addEventListener('click', this._startQuiz);
    }

    _startQuiz(event){
        event.preventDefault();
        this.score = 0;
        this.currentQuestion = 0;
        this.startScreen.style.display = 'none';
        this.quizContent.style.display = 'block';



        this._getQuestion(this.questionList[0]).then(question => {
            this._generateQuestion(question);
            this._startTimer();
        }).catch(error => {
            this._handleError(error);
        });
    }

    _startTimer() {
        this.startTime = performance.now();
        this.timer = setInterval(()=>{
            this.time = performance.now() - this.startTime;
            let fT = this.fullTime + this.time;
            let seconds = Math.floor(fT/1000);
            let miliseconds = Math.ceil(fT - (seconds * 1000));
            let minutes = Math.floor(seconds/60);
            seconds = seconds - (minutes * 60);

            let sC = this.timerContainer.querySelector('[data-element="seconds"]');
            let mC = this.timerContainer.querySelector('[data-element="minutes"]');
            let msC = this.timerContainer.querySelector('[data-element="miliseconds"]');
            sC.innerText = seconds.toString().padStart(2, "0");
            mC.innerText = minutes.toString().padStart(2, "0");
            msC.innerText = miliseconds.toString().padStart(3, "0");
        },10)
    }
    _stopTimer() {
        clearInterval(this.timer);
        this.timer = null;
        this.fullTime = this.fullTime + this.time;
    }

    _nextQuestion(event){
        event.preventDefault();
        this._stopTimer();

        let checkedAnswer = event.target.closest('[data-value]').getAttribute('data-value');
        if(checkedAnswer){
            this._checkAnswer(checkedAnswer).then(result => {
                if(result.correct){
                    this.score++;
                }
                if(result.nextQuestion !== null){
                    this._generateQuestion(result.nextQuestion);
                    this.currentQuestion++;
                    this._startTimer();

                    if (this.currentQuestion === this.questionCount-1) {
                        this.nextButton.innerText = 'Zakończ quiz';
                    }
                } else {
                    this._showLastStep();
                }
            }).catch(error => {
                this._handleError(error);
            })
        } else {
            this._handleError('no_answer_selected');
        }
    }

    _generateQuestion(question){
        this.question.innerHTML = '';
        if(question.type === 'text') {
            this.question.innerText = question.question;
        } else {
            let img = document.createElement('img');
            img.setAttribute('src', question.question);
            this.question.append(img);
        }
    }

    _showLastStep(){
        this.scoreContainer.innerHTML = this.score + '/' + this.questionCount;
        let seconds = Math.floor(this.fullTime/1000);
        let miliseconds = Math.ceil(this.fullTime - (seconds * 1000));
        let minutes = Math.floor(seconds/60);
        seconds = seconds - (minutes * 60);

        let sC = document.createElement('<span>');
        let mC = document.createElement('<span>');
        let msC = document.createElement('<span>');
        sC.innerText = seconds.toString().padStart(2, "0");
        mC.innerText = minutes.toString().padStart(2, "0"); 
        msC.innerText = miliseconds.toString().padStart(3, "0");

        this.resultTime.append(mC);
        this.resultTime.append(':');
        this.resultTime.append(sC);
        this.resultTime.append(':');
        this.resultTime.append(msC);

        this.quizContent.style.display = 'none';
        this.resultScreen.style.display = 'flex';

        setTimeout(() => {
            window.location.href = '/';
        }, 30000);
    }

    _sendForm(e){
        e.preventDefault();
        let data = {
            score: this.score,
            time: this.fullTime,
            name: '',
            email: ''
        };

        let nameInput = this.formContainer.querySelector('[name="name"]');
        let emailInput = this.formContainer.querySelector('[name="email"]');
        if(nameInput !== null) data.name = nameInput.value;
        if(emailInput !== null) data.email = emailInput.value;

        this._submitForm(data).then(result => {
            this.formContainer.innerHTML = '';
            this.formContainer.style.display = 'none';
            this.scoreContainer.classList.add('quiz__score--static');
            this.scoreContainer.classList.add('quiz__score--static-end');
            this.scoreContainer.innerText = 'Dziekujemy za przesłanie odpowiedzi, jeśli zakwalifikujesz się do otrzymania albumu skontaktujemy się z tobą za pomocą wskazanego adresu email.';
            this.scoreContainer.style.display = 'block';
        }).catch(error => {
            this._handleError(error);
        })
    }
    _generateForm(){
        let form = document.createElement('form');
        form.classList.add('form')

        let form_row_name = document.createElement('div');
        form_row_name.classList.add('form__row');

        let form_input_group_name = document.createElement('div');
        form_input_group_name.classList.add('form__input-group');

        let form_input_label_name = document.createElement('label');
        form_input_label_name.setAttribute('for', 'name');
        form_input_label_name.innerText = 'Imię i Nazwisko';

        let nameInput = document.createElement('input');
        nameInput.setAttribute('name', 'name');
        nameInput.setAttribute('type', 'text');
        nameInput.setAttribute('required', 'required');
        nameInput.setAttribute('placeholder', 'Imię i Nazwisko');

        let form_input_group_email = document.createElement('div');
        form_input_group_email.classList.add('form__input-group');

        let form_input_label_email = document.createElement('label');
        form_input_label_email.setAttribute('for', 'name');
        form_input_label_email.innerText = 'Email';

        let emailInput = document.createElement('input');
        emailInput.setAttribute('name', 'email');
        emailInput.setAttribute('type', 'email');
        emailInput.setAttribute('required', 'required');
        emailInput.setAttribute('placeholder', 'Email');

        let form_row_rules = document.createElement('div');
        form_row_rules.classList.add('form__row');

        let label_rules = document.createElement('label');
        label_rules.classList.add('form__rules');

        let agreement = document.createElement('input');
        agreement.setAttribute('type', 'checkbox');
        agreement.setAttribute('required', 'required');
        agreement.setAttribute('id', 'agreement');

        let span = document.createElement('span');
        span.innerHTML = 'Wypełnienie formularza oznacza, że podane w nim dane osobowe będą przetwarzane w celu nawiązania kontaktu zgodnie z <a href="/regulamin-quizu/" target="_blank">regulaminem konkursu</a>.';

        let form_row_button = document.createElement('div');
        form_row_button.classList.add('form__row');

        let button = document.createElement('button');
        button.setAttribute('type', 'submit');
        button.innerHTML = 'Prześlij';
        button.classList.add('button');
        button.classList.add('button--bg-blue');
        // button.classList.add('button button--bg-blue');

        form_input_group_name.append(form_input_label_name);
        form_input_group_name.append(nameInput);

        form_input_group_email.append(form_input_label_email);
        form_input_group_email.append(emailInput);

        form_row_name.append(form_input_group_name);
        form_row_name.append(form_input_group_email);

        label_rules.append(agreement);
        label_rules.append(span);
        form_row_rules.append(label_rules);

        form_row_button.append(button);

        form.append(form_row_name);
        form.append(form_row_rules);
        form.append(form_row_button);
        return form;
    }
    _handleError(error){
        console.log(error);
        let element = document.createElement('div');

        const modal = document.createElement('div');
        const close = document.createElement('a');
        close.classList.add('modal__close');
        close.innerHTML = '×';

        modal.classList.add('modal');
        modal.classList.add('modal--quiz-error');
        modal.setAttribute('data-element', 'modal');

        if (error === 'no_answer_selected') {
            modal.innerText = 'Musisz wybrać odpowiedź.';
        } else {
            modal.innerText = 'Wystąpił błąd, odśwież stronę i spróbuj ponownie.';
        }

        modal.appendChild(close);
        element.appendChild(modal);

        let instance = basicLightbox.create(element);
        instance.show();

        close.addEventListener('click', (e) => {
            instance.close();
            instance.innerHTML = '';
        });
        // alert(error);
    }

    _checkAnswer(answer){

        let next = this.currentQuestion + 1;
        let url = '/wp-json/netivo/v1/quiz/';
        let body = 'question='+this.questionList[this.currentQuestion]+'&answer='+answer
        if(next < this.questionList.length) body += '&next='+this.questionList[next];
        return fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            body: body,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _getQuestion(question){
        let url = '/wp-json/netivo/v1/quiz/?question='+question;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _submitForm(data){
        let formBody = Object.keys(data).map(key => {
            if (typeof data[key] === "object" && !Array.isArray(data[key]) && data[key] !== null) {
                return Object.keys(data[key]).map(k => {
                    return key + '[' + k + ']=' + data[key][k];
                }).join('&');
            }
            return key + '=' + data[key]
        }).join('&');
        let url = '/wp-json/netivo/v1/quiz/';
        return fetch(url, {
            method: 'PUT',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formBody
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }

}

export default Quiz;